
<template>
    <el-container class="form-container">
        <LoadingDots :loading="loading"></LoadingDots>
        <el-header class="form-title">
            <h1>Contact us</h1>
            <div v-if="subTitle" class="subtitle-text">
                <p>{{ subTitle }}</p>
            </div>
        </el-header>
        <el-main class="form-content">
            <div v-show="!submitted">
                <div class="required-fields-indicator-message">{{ requiredFieldsIndicatorMessage }}</div>
                <el-form
                    ref="contactUsFormRef"
                    :model="contactUsForm.fields"
                    :size="formSize"
                    :rules="contactUsFormRules"
                >
                    <template v-for="(props, field) in contactUsForm.fields">
                        <el-form-item 
                            v-if="props.isShow"
                            v-bind:key="field"
                            :label="(props.type !== 'checkbox' && props.type !== 'placeHolder') ? props.label : ''" 
                            :prop="field"
                            :style="props.type === 'placeHolder' ? 'width: 1px;' : ''"
                            :class = "props.type === 'textarea'|| props.id === 'inquiryPurpose' ? 'el-form-item-gridstyle' : ''"
                            @focus="dispatchTrackInteraction"
                        >  
                            <el-input v-if="props.type === 'input'"
                                v-model="props.value" 
                                :class="props.isError ? 'validation-error' : ''"
                                maxlength="999"
                                clearable
                            />
                            <el-select v-if="props.type === 'select'" 
                                v-model="props.value" 
                                placeholder=" --- Please Select --- " 
                                :class="props.isError ? 'validation-error' : ''"
                                filterable
                                :multiple="Boolean(props.multipleSelect)"
                                :collapse-tags="Boolean(props.multipleSelect)"
                                :collapse-tags-tooltip="Boolean(props.multipleSelect)"
                                @focus="dispatchTrackInteraction"
                            >
                                <el-option v-for="item in props.options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                            <el-input v-if="props.type === 'textarea'"
                                type="textarea"
                                v-model="props.value" 
                                :class="props.isError ? 'validation-error' : ''"
                                maxlength="999"
                                :autosize="{ minRows: 5, maxRows: 8 }"
                                clearable 
                            />
                        </el-form-item>
                    </template>

                    <el-alert 
                        v-show="recaptchaInvalid"
                        class="recaptcha-invalid-message" 
                        :title="recaptchaInvalidMessage" 
                        type="error"
                    />
                    <el-alert 
                        v-if="formError"
                        class="recaptcha-invalid-message" 
                        title="Please fill in required fields" 
                        type="error"
                    />

                    <el-form-item class="submit-button">
                        <el-button 
                            type="primary" 
                            :disabled="submitDisabled"
                            @click="onSubmit(contactUsFormRef)"
                        >
                            submit
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            
            <div v-show="submitted">
                <el-card class="post-submit-message" shadow="hover">
                    <h3 class="post-submit-message-title">
                        Thank You
                    </h3>
                    <br/>
                    <p class="post-submit-message-text">
                        Thank you for your request! A manager will contact you soon.
                    </p>
                </el-card>
            </div>
        </el-main>
    </el-container>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted, defineProps } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import { getParameter } from '../../../../../../../dow-platform/ui.frontend/src/utils/url'
import { getEloquaParameters } from '../../../../../../../dow-platform/ui.frontend/src/utils/eloqua'
import LoadingDots from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/commerce/shared/loading-dots'
import type { ContactUsRuleForm } from './FormInterface'
import { useStore } from 'vuex'

const loading = ref(false)
const submitted = ref(false)
const submitDisabled = ref(false)
const requiredFieldsIndicatorMessage = ref('* Indicates required field')

const captchaToken = ref('')
const recaptchaInvalid = ref(false)
const recaptchaInvalidMessage = ref('An error occurred while submitting your form. Please try again in five minutes')
const formError = ref(false)

const dispatchTrackInteraction = () => {
        store.dispatch('form/trackInteraction', formComponent);
    }
const fromURLtoFORM = ref({
    campaignID: '', 
    channel: '', 
    cid: '', 
    source: '', 
    elqCampaignID: '', 
});

const props = defineProps({
    contactUsForm: {
        type: Object,
        default: {},
    },
    formSize: {
        type: String,
        default: '',
    },
    elqFormName: {
        type: String,
        default: '',
    },
    elqSiteId: {
        type: String,
        default: '',
    },
    subTitle: {
        type: String,
        default: '',
    }
});
const store = useStore()

const formComponent = Object.keys(window.adobeDataLayer.getState().component).find(key => key.includes('form'));

const contactUsFormRef = ref<FormInstance>()

const requiredFieldError = 'This field is required'

const emailRegex = /^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i	

const phoneNumberRegex = /(^[(][0-9]+[)][0-9 -]+$)|(^[0-9 -]+$)|(^[+][0-9 -]+$)|(^[(][+][0-9]+[)][0-9 -]+$)/s

const nameValidator = (rule: any, field: any, callback: any) => {
    if (field.value === '') {
        callback(new Error(requiredFieldError))
        field.isError = true
    } else if (field.value.length > 255) {
        callback(new Error('Length should be less than 256'))
        field.isError = true
    } else {
        callback()
        field.isError = false
    }
}

const emailValidator = (rule: any, field: any, callback: any) => {
    if (field.value === '') {
        callback(new Error(requiredFieldError))
        field.isError = true
    } else if (!emailRegex.test(field.value)) {
        callback(new Error('Invalid email address'))
        field.isError = true
    } else {
        callback()
        field.isError = false
    }
}

const phoneValidator = (rule: any, field: any, callback: any) => {
    if (field.value !== '' && !phoneNumberRegex.test(field.value)) {
        callback(new Error('Invalid phone number'))
        field.isError = true
    } else {
        callback()
        field.isError = false
    }
}

const requiredValidator = (rule: any, field: any, callback: any) => {
    if (field.value === '') {
        callback(new Error(requiredFieldError))
        field.isError = true
    } else {
        callback()
        field.isError = false
    }
}

const selectValidator = (rule: any, field: any, callback: any) => {
    if (typeof field.value === 'string') {
        if (field.value === '') {
            callback(new Error(requiredFieldError))
            field.isError = true
        } else {
            callback()
            field.isError = false
        }
    } else {
        if (field.value.length === 0) {
            callback(new Error(requiredFieldError))
            field.isError = true
        } else {
            callback()
            field.isError = false
        }
    }
}

const contactUsFormRules = reactive<FormRules<ContactUsRuleForm>>({
    firstName: [{ validator: nameValidator, trigger: 'blur' }], 
    lastName: [{ validator: nameValidator, trigger: 'blur' }], 
    email: [{ validator: emailValidator, trigger: 'blur' }],
    country: [{ validator: selectValidator, trigger: 'change' }],
    additionalInfo: [{ validator: requiredValidator, trigger: 'blur' }],
    inquiryPurpose: [{ validator: selectValidator, trigger: 'change' }],
    productApplication: [{ validator: selectValidator, trigger: 'change' }],
    company: [{ validator: requiredValidator, trigger: 'blur' }],
    busPhone: [{ validator: phoneValidator, trigger: 'blur' }], 
})

onMounted(() => {
    initUrlQueryParameters();
})

function initUrlQueryParameters(): void {
    let cidString = getParameter('cid').toString();
    if(cidString !== ''){
        let elqFields = getEloquaParameters("cid="+cidString)
        fromURLtoFORM.value.cid = elqFields[0].value
        fromURLtoFORM.value.elqCampaignID = elqFields[1].value
        fromURLtoFORM.value.campaignID = elqFields[2].value
        fromURLtoFORM.value.channel = elqFields[3].value
        fromURLtoFORM.value.source = elqFields[4].value

    }
}

const onSubmit = async (formEl: FormInstance | undefined) => {   
    if (!formEl) { return }
    await formEl.validate((valid) => {
        if (valid) {
            formError.value = false
            postTalentForm()
        } else {
            formError.value = true
            submitDisabled.value = false
        }
    })
}

async function postTalentForm() {

    loading.value = true

    let formData : any
    formData = []

    formData.push({name: 'elqFormName', value: props.elqFormName})
    formData.push({name: 'elqSiteId', value: props.elqSiteId})
    formData.push({name: 'campaignID', value: fromURLtoFORM.value.campaignID})
    formData.push({name: 'channel', value: fromURLtoFORM.value.channel})
    formData.push({name: 'cid', value: fromURLtoFORM.value.cid})
    formData.push({name: 'source', value: fromURLtoFORM.value.source})
    formData.push({name: 'elqCampaignId', value: fromURLtoFORM.value.elqCampaignID})
    formData.push({name: 'firstName', value:  props.contactUsForm.fields.firstName.value})
    formData.push({name: 'lastName', value: props.contactUsForm.fields.lastName.value})
    formData.push({name: 'emailAddress', value: props.contactUsForm.fields.email.value})
    formData.push({name: 'country', value: props.contactUsForm.fields.country.value})
    formData.push({name: 'additionalInfo', value: props.contactUsForm.fields.additionalInfo.value})
    formData.push({name: 'busPhone', value: props.contactUsForm.fields.busPhone.value})
    formData.push({name: 'address1', value: props.contactUsForm.fields.address1.value})
    formData.push({name: 'address2', value: props.contactUsForm.fields.address2.value}) 
    formData.push({name: 'city', value: props.contactUsForm.fields.city.value})
    formData.push({name: 'stateProv', value: props.contactUsForm.fields.stateProv.value})
    formData.push({name: 'zipPostal', value: props.contactUsForm.fields.zipPostal.value})
    if(props.contactUsForm.fields.company)
        formData.push({name: 'company', value: props.contactUsForm.fields.company.value})       
    if(props.contactUsForm.fields.inquiryPurpose) 
        formData.push({name: 'inquiryPurpose', value: props.contactUsForm.fields.inquiryPurpose.value})
    if(props.contactUsForm.fields.productApplication) 
        formData.push({name: 'productApplication', value: props.contactUsForm.fields.productApplication.value})
   
    captchaToken.value = await window.grecaptcha.execute(window.captchaV3SiteKey, {action: 'ContactUsForm'})

    if (Boolean(captchaToken.value)) {
        recaptchaInvalid.value = false
        formData.push({name: 'g-recaptcha-response', value: captchaToken.value})
        await $.ajax({
            type: 'POST',
            url: '/.dow.formsub.servlet.json',
            data: $.param(formData),
            success: function (data) {
                loading.value = false
                submitted.value = true
                submitDisabled.value = false
                store.dispatch('form/trackComplete', formComponent)
            },
            error: function (data) {
                let response = data
                console.log(
                    'Contact Us Form: Error during submitting form. ' + response.responseText
                )
                store.dispatch('form/trackError', { form: formComponent, error: response.responseText })
                recaptchaInvalid.value = true
                submitDisabled.value = false
                loading.value = false
            }
        })
    } else {
        recaptchaInvalid.value = true
        submitDisabled.value = false
        loading.value = false
    }
}

</script>